import {Injectable} from '@angular/core';
import {CVSHttpClient} from "angular-component-library";
import {Observable} from "rxjs";
import {Feedback} from "../models/feedback";
import {AdminTaskMessages} from "../models/admin-task-messages";
import {EnvironmentLoaderService} from "../../config/environment-loader.service";
import {EnvConfig} from "../../config/env-config";

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  protected readonly envConfig: EnvConfig;
  protected readonly backendBaseUrl: string;

  constructor(private cvsHttpClient: CVSHttpClient, private readonly envService: EnvironmentLoaderService) {
    this.envConfig = envService.getEnvConfig()
    this.backendBaseUrl = this.envConfig.backendBaseUrl;
  }

  getAdminTaskMessages(date: string): Observable<AdminTaskMessages> {
    return this.cvsHttpClient.get(`${this.backendBaseUrl}/maintenance?date=${date}`);
  }

  saveMessage(feedback: Feedback): Observable<string> {
    return this.cvsHttpClient.post(`${this.backendBaseUrl}/feedback/message`, feedback);
  }
}
