<br>
<br>
<h1 class="welcomeTitle">{{ title }}</h1>
<div class="parentGrid">
    <div class="gridHeader">
        <h2 class="myDashboard">My Dashboards</h2>
        <div class="searchBox">
            <input [(ngModel)]="searchValue" (ngModelChange)="searchFilter(this.searchValue)"
                    type="search" class="filterSearch"  placeholder="Type to search dashboards">
        </div>
        <mat-button-toggle-group class="toggle-button" [(value)] = "selectedValue">
            <mat-button-toggle  *ngFor="let item of toggleOptions;"
                                (change)="selectionChanged($event)" [value]="item">{{item}}</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div>
        <cvs-loader-overlay-container [isLoading]="showSpinner">
            <mat-tab-group *ngIf="selectedTabs.length >= 1"  [(selectedIndex)]="selectedTabIndex"
                           (selectedTabChange)="selectedTabValue($event)  ">
                <mat-tab *ngFor="let tabTitle of selectedTabs; let index = index;"   class="tab-title" >
                    <ng-template mat-tab-label>{{tabTitle}} </ng-template>
                    <div id="allViews" *ngIf="selectedValue === 'Thumbnail'">
                        <div *ngIf="showSpinner" style="margin-top: 350px"></div>
                        <div *ngIf="!showNoDashboardOverlay">
                            <ul class="ul_top_hypers" >
                                <li class="dashboardViewLi" *ngFor="let aView of pagedList">
                                    <div class="imageWordStarContainer">
                                        <a class="dashBoardComponent" href="{{aView.url}}">
                                            <div class="imageContainer">
                                                <a class="allImage">
                                                    <img alt="all image" class="imageView" [src]="aView.image">
                                                </a>
                                            </div>
                                            <div class="dashUpdate">
                                                <div class="dashboardNameWordWrap">
                                                {{aView.name}}
                                                </div>
                                                <div class="updatedDays">
                                                    {{aView.folderName}}
                                                </div>
                                                <div class="updatedDays">
                                                    Updated {{aView.updatedAt}}
                                                </div>
                                            </div>
                                        </a>
                                        <div class="rectangleStarBox">
                                            <mat-icon  *ngIf="!aView.favorite" svgIcon="star--s" class="starIcon" (click)="processAddImage(aView)"></mat-icon>
                                            <mat-icon  *ngIf="aView.favorite" svgIcon="star-f--s" class="starFilledIcon" (click)="deleteFavoriteImage(aView)"></mat-icon>
                                      </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div *ngIf="showNoDashboardOverlay && selectedValue === 'Thumbnail'">
                        <ul>
                            <div class="noDashboardsOverlay">
                                No Dashboards to display
                            </div>
                        </ul>
                    </div>
                    <div *ngIf="selectedValue === 'List'">
                        <div *ngIf="showNoDashboardOverlay">
                            <div class="noDashboardsOverlay">
                                No Dashboards to display
                            </div>
                        </div>
                        <div *ngIf="!showNoDashboardOverlay">
                            <div class="listFirstRow">
                                <h3 class="listHeader">
                                    Dashboard Selections
                                </h3>
                                <div><mat-icon svgIcon="menu--s" class="listMenuIcon"></mat-icon></div>
                            </div>
                            <mat-list style="width: 98%;">
                                <a mat-list-item *ngFor="let aView of allViews;"
                                href="{{aView.url}}" class="listView"
                                id="navLink-{{aView.name}}">
                                    <mat-icon  *ngIf="!aView.favorite" svgIcon="star--s" class="starListIcon"></mat-icon>
                                    <mat-icon  *ngIf="aView.favorite" svgIcon="star-f--s" class="starFilledListIcon"></mat-icon>
                                    <div class="listDashboardName">
                                        {{ aView.name }}
                                    </div>
                                </a>
                            </mat-list>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <div *ngIf="!showNoDashboardOverlay && !showSpinner && selectedValue ==='Thumbnail' ">
                <mat-paginator #paginator (page)="OnPageChange($event)" hidePageSize="true"
                               [pageSize]="pageSize" [length]="length"></mat-paginator>
            </div>
        </cvs-loader-overlay-container>
    </div>
</div>
<br>
<br>
<div class="parentGrid">
    <div class="gridHeader">
        <h2 class="myDashboard">Explore CMX</h2>
    </div>
    <div>
        <mat-tab-group *ngIf="selectedTabs.length >= 1"  [(selectedIndex)]="cmxSelectedTabIndex"
                       (selectedTabChange)="cmxSelectedTabValue($event)  ">
            <mat-tab *ngFor="let tabTitle of cmxSelectedTabs; let index = index;"   class="tab-title" >
                <ng-template mat-tab-label>{{tabTitle}} </ng-template>
                <div id="allCmxViews" >
                    <!--<div *ngIf="!showNoDashboardOverlay">-->
                        <ul class="ul_top_hypers" >
                            <li class="dashboardViewLi" *ngFor="let aView of cmxViewList">
                                <div class="cmxImageWordTypeContainer">
                                    <div class="cmxImageNameDetails">
                                        <a class="cmxDashBoardComponent" href="{{aView.url}}" target="_blank">
                                            <div class="imageContainer">
                                                <a class="allImage">
                                                    <img alt="all image" class="imageView" src="{{aView.image}}">
                                                </a>
                                            </div>
                                            <div class="cmxDashboardNameWordWrap">
                                                {{aView.name}}
                                            </div>
                                            <div class="cmxDashboardDetails">
                                                {{aView.details}}
                                            </div>
                                            <div class="rectangleMediaType">
                                                <div class="rectangleMediaTypeText">
                                                    {{aView.cmxType}}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    <!--</div>-->
                </div>
                <!--<div *ngIf="showNoDashboardOverlay && selectedValue === 'Thumbnail'">
                    <ul>
                        <div class="noDashboardsOverlay">
                            No Dashboards to display
                        </div>
                    </ul>
                </div>-->
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<table class="mainTable">
    <tr class="feedbackBtn">
        <button class="cvs-btn-ghost" (click)="addFeedback()">
            <mat-icon svgIcon="envelope--s"></mat-icon>
            Feedback
        </button>
    </tr>
</table>
