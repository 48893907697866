import {Component, Inject, OnInit} from '@angular/core';
import {AdminTaskMessages} from "../models/admin-task-messages";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent implements OnInit  {
  notificationMessages: AdminTaskMessages;
  maintenanceMessageHeadline: string = '';
  maintenanceMessageBody = '';
  notificationMessageHeadline = '';
  notificationMessageBody = '';
  otherMessageHeadline = '';
  otherMessageBody = '';
  constructor(@Inject(MAT_DIALOG_DATA) public data: AdminTaskMessages) {
    this.notificationMessages = this.data;
  }

  ngOnInit(): void {
    this.getMaintenanceMessage();
  }

  getMaintenanceMessage() {
    if (this.notificationMessages.systemMaintenanceMessage) {
      this.maintenanceMessageHeadline = 'Scheduled Maintenance';
      this.maintenanceMessageBody = this.notificationMessages.systemMaintenanceMessage;
    }
    if (this.notificationMessages.notificationMessage) {
        this.notificationMessageHeadline = 'Notification';
        this.notificationMessageBody = this.notificationMessages.notificationMessage;
    }
    if (this.notificationMessages.otherMessage) {
        this.otherMessageHeadline = 'Other';
        this.otherMessageBody = this.notificationMessages.otherMessage;
    }
  }
}
