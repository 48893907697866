import {Component, ElementRef, OnInit, ViewChild,} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TableauService} from "../services/tableau.service";
import {TranslateService} from "@ngx-translate/core";
import {AuthorizationService, CVSBaseConfig, NavItemConfig, SubNavItem} from "angular-component-library";
import {EnvConfig} from "../../config/env-config";
import {EnvironmentLoaderService} from "../../config/environment-loader.service";
import { TableauViz, TableauEventType, Toolbar } from '@tableau/embedding-api';

//declare const tableau: any;

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {
  name!: string;
  viz = new TableauViz();
  isLoading: boolean = false;
  @ViewChild("vizContainer") vizContainer!: ElementRef;
  protected readonly envConfig: EnvConfig;

  constructor(private route: ActivatedRoute,
              private authorizationService: AuthorizationService,
              private tableauService: TableauService,
              private translateService : TranslateService,
              private readonly envService: EnvironmentLoaderService) {
    this.envConfig = envService.getEnvConfig()
  }

  ngOnInit(): void{
    this.route.params.subscribe(param => {
      this.isLoading = true;
      this.name = param.name;
      this.tableauService.getView(param.id).subscribe(view => {
        this.isLoading = false;
        this.initViz(view.viewUrl);
      });
    });
  }

  private initViz(viewUrl: string): void {
    const product:string = this.translateService.currentLang;
    let productTableauBasedUrl: string | null;
    let displayToolbar: string | null;

    productTableauBasedUrl  =   this.envConfig.authConfig.tableauBaseUrl;
    this.viz.src = `${productTableauBasedUrl}/views/${viewUrl}?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:refresh=yes&:linktarget=_self`;
    this.viz.toolbar = this.envConfig.viewToolbar?Toolbar.Top:Toolbar.Hidden;
    console.log('toolbar: '+ this.viz.toolbar)
    this.viz.addEventListener(TableauEventType.MarkSelectionChanged, this.vizContainer.nativeElement);
    document.getElementById('tableauViz')?.appendChild(this.viz);
  }

}