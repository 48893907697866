import {Injectable} from '@angular/core';
import {CVSHttpClient} from "angular-component-library";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Project} from "../models/project";
import {View} from "../models/View";
import {AllView} from "../models/all-view";
import {EnvConfig} from "../../config/env-config";
import {EnvironmentLoaderService} from "../../config/environment-loader.service";
import {Feedback} from "../models/feedback";
import {FavoriteView} from "../models/favoriteView";

@Injectable({
  providedIn: 'root'
})
export class TableauService {

  protected readonly envConfig: EnvConfig;
  protected readonly backendBaseUrl: string;
  //protected readonly product: string;

  constructor(private cvsHttpClient: CVSHttpClient, private readonly envService: EnvironmentLoaderService) {
    this.envConfig = envService.getEnvConfig()
    this.backendBaseUrl = this.envConfig.backendBaseUrl;
  }

  getProjects(email: string): Observable<Project[]> {
    return this.cvsHttpClient.get(`${this.backendBaseUrl}/projects/${email}`)
      .pipe(map(data => data.projects));
  }

  getView(workbookId: string): Observable<View> {
    return this.cvsHttpClient.get(`${this.backendBaseUrl}/view/${workbookId}`)
  }

  getFrequentImg(email: string): Observable<AllView[]> {
    return this.cvsHttpClient.get(`${this.backendBaseUrl}/view/frequentlyViewed/${email}`)
      .pipe(map(data => data.dashboards));
  }

  getAllViewImg(email: string): Observable<AllView[]> {
    return this.cvsHttpClient.get(`${this.backendBaseUrl}/view/allViewed/${email}`)
        .pipe(map(data => data.dashboards));
  }

  getRecentViewImg(email: string): Observable<AllView[]> {
    return this.cvsHttpClient.get(`${this.backendBaseUrl}/view/recentViewed/${email}`)
        .pipe(map(data => data.dashboards));
  }

  getFavoriteImg(email: string): Observable<AllView[]> {
    return this.cvsHttpClient.get(`${this.backendBaseUrl}/view/favorites/${email}`)
        .pipe(map(data => data.dashboards));
  }

  addFavoriteImg(favoriteView:FavoriteView ): Observable<boolean> {
    return this.cvsHttpClient.put(`${this.backendBaseUrl}/view/addfavorite/`, favoriteView)
        .pipe(map(data => data.dashboards));
  }

  deleteFavoriteImg(email: string, id:string): Observable<boolean> {
    return this.cvsHttpClient.delete(`${this.backendBaseUrl}/view/deletefavorite/${email}/${id}`)
        .pipe(map(data => data.dashboards));
  }
}
