import {Component, ViewEncapsulation} from '@angular/core';
import {
  AuthCodePKCEService,
  AuthorizationService, CVSAlertService,
  CVSAuthConfig,
  CVSBaseConfig,
  MenuState,
  NavItemConfig,
  SideNavConfig,
  SubNavItem
} from "angular-component-library";
import {Router} from "@angular/router";
import {TableauService} from "./services/tableau.service";
import {mergeMap} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import Utils from "./shared/utility/Utils";
import {EnvConfig} from "../config/env-config";
import {EnvironmentLoaderService} from "../config/environment-loader.service";
import {MessageService} from "./services/message.service";
import {MatDialog} from "@angular/material/dialog";
import {NotificationsDialogComponent} from "./notification-dialog/notifications-dialog.component";
import {AdminTaskMessages} from "./models/admin-task-messages";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  baseConfig: CVSBaseConfig = {  } as CVSBaseConfig;
  authConfig: CVSAuthConfig = { authenticationEnabled:true } as CVSAuthConfig;
  sideNavConfig!: SideNavConfig;
  protected readonly envConfig: EnvConfig;
  notificationMessages: AdminTaskMessages | undefined;
  notificationsFlag = true;
  notificationsCount: number = 0;

  constructor(public router: Router, private authorizationService: AuthorizationService, private matDialog: MatDialog,
              private authCodePKCEService: AuthCodePKCEService,private tableauService: TableauService,
              public translateService: TranslateService, private messageService: MessageService,
              private readonly envService: EnvironmentLoaderService) {
    this.envConfig = envService.getEnvConfig()
    const originalURL = window.location.href;
    let productName = this.envConfig.defaultProduct;
    const map = new Map(Object.entries(this.envConfig.authConfig));
    const date = new Date();
    const [currentDate] = new Date(date).toISOString().split('T');
    map.forEach((value, key) => {
      if(originalURL.startsWith(value.matcher)){
          productName = key;
        }
    })
    translateService.setDefaultLang(productName);
    translateService.use(productName);
    this.translateService.get(this.translateService.currentLang).toPromise().then();
    const values = this.envConfig.authConfig
    this.authConfig = {
       ...values,
        useAccessToken: false
      } as CVSAuthConfig;
      this.baseConfig = {
        homeRoute: `/home`,
        appName: 'CVS Media Exchange Portal',
      } as CVSBaseConfig;

    this.sideNavConfig = {
      sideNavItems: [],
      iconMenu: true,
      menuState: MenuState.Open,
    } as SideNavConfig;

    this.authorizationService.getAuthorizedUser().pipe(
      mergeMap(user => this.tableauService.getProjects(user.email))
    ).subscribe(projects => {
      const navItemConfig = projects.map(project => {
        return {
          name: Utils.formatLongName(project.name),
          hasIconMenu:true,
          icon:'dashboard--s',
          subNavItems : Utils.sortItems(project.workbooks.map(workbook => {
          return {
            name: Utils.formatLongName(workbook.name),
            routerLink: `dashboard/${workbook.id}/${workbook.name}`
          } as SubNavItem;
        }))
        } as NavItemConfig;
      });

      this.sideNavConfig.sideNavItems = [{name: 'Home', routerLink: '/home', icon:'home--s', hasIconMenu:true}, ...Utils.sortItems(navItemConfig)];
      this.baseConfig = {
        homeRoute: '/home',
        appName: 'CVS Media Exchange Portal',
      } as CVSBaseConfig;

      this.messageService.getAdminTaskMessages(currentDate).subscribe(adminTaskMessages => {
        if(adminTaskMessages){
          this.notificationMessages = adminTaskMessages;
          for (const [key, value] of Object.entries(this.notificationMessages)) {
            if (value !== null) {
              this.notificationsCount++;
            }
            this.notificationsFlag = this.notificationsCount === 0;
          }
        }
      })
    });
  }

  notificationDialog() {
    this.matDialog.open(NotificationsDialogComponent, {
      width:'528px',
      height:'410px',
      data: this.notificationMessages
    })
  }

}
