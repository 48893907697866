<div class="dialog-header">
    <h1 class="title">Notifications</h1>
    <button class="cvs-icon-only-btn" matDialogClose>
        <mat-icon svgIcon="close-btn--s"></mat-icon>
    </button>
</div>
<div>
    <div *ngIf="this.notificationMessages.systemMaintenanceMessage" class="alert-message maintenance-message">
        <mat-icon class="alert-icon" svgIcon="warning-circle--s"></mat-icon>
        <h2 class="alert-heading">
            {{maintenanceMessageHeadline}}
        </h2>
        <div class="alert-body">{{maintenanceMessageBody}}</div>
    </div>
    <div *ngIf="this.notificationMessages.notificationMessage" class="alert-message other-message">
        <mat-icon class="alert-icon" svgIcon="warning-circle--s"></mat-icon>
        <h2 class="alert-heading">
            {{notificationMessageHeadline}}
        </h2>
        <div class="alert-body">{{notificationMessageBody}}</div>
    </div>
    <div *ngIf="this.notificationMessages.otherMessage" class="alert-message other-message">
        <mat-icon class="alert-icon" svgIcon="warning-circle--s"></mat-icon>
        <h2 class="alert-heading">
            {{otherMessageHeadline}}
        </h2>
        <div class="alert-body">{{otherMessageBody}}</div>
    </div>
</div>