import {NavItemConfig} from "angular-component-library";

export default class Utils {

  static sortItems(data: NavItemConfig[]): NavItemConfig[] {
    return data.sort((a,b) => {
      if (a.name! > b.name!) {
        return 1;
      }
      if (a.name! < b.name!) {
        return -1;
      }
      return 0;
    });
  }

  static formatLongName(name: string): string {
    let formattedNameString = name;
    const formattedName = [...name];
    if(formattedName.length >= 24){
      for (let i = 24; i >= 0; i--) {
        // Covering an edge case where space is right at the margin cutoff and no transformation is needed.
        if(formattedName[i] === ' '){
          break;
        }
        if(formattedName[i] === '_'){
          formattedNameString = name.substring(0, i) + ' - ' + Utils.formatLongName(name.substring(i));
          break;
        }
      }
    }
    return formattedNameString;
  }

  static toDateAgo(value: string): string {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29)
        return 'Just now';
      const intervals: { [key: string]: number } = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago';
          } else {
            return counter + ' ' + i + 's ago';
          }
      }
    }
    return value;
  }

}
