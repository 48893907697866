<!--suppress HtmlUnknownBooleanAttribute -->
<cvs-loader-overlay-container [isLoading]="isLoading">
    <div class="dialog-header">
        <h1 class="title">Feedback Form</h1>
        <button class="cvs-icon-only-btn" matDialogClose>
            <mat-icon svgIcon="close-btn--s"></mat-icon>
        </button>
    </div>
    <p style="font-family: Helvetica,serif; margin-top: 10px">Fields marked are required.</p>
    <br>
    <dialog-alert-placeholder></dialog-alert-placeholder>
    <div>
        <label class="label" for="feedbackTypes">Feedback Type*</label><br>
        <div class="selectWrapper">
            <select class="selectBox" id="feedbackTypes" name="feedbackTypes" [(ngModel)]="selectedValue">
                <option *ngFor="let feedbackType of feedbackTypes" [ngValue]="feedbackType">{{feedbackType}}</option>
            </select>
        </div>
    </div>
    <br>
    <form [formGroup]="feedbackForm">
        <div class="label">
            <label for="textarea">Description*</label>
        </div>
        <div>
            <mat-form-field appearance="outline" class="feedbackTextArea">
        <textarea id="textarea" rows="5" matInput placeholder="Type your feedback here" formControlName="feedbackText"
                  [maxlength]="maxLength"></textarea>
                <mat-hint>{{this.feedbackForm.controls.feedbackText.value.length}}/{{maxLength}}</mat-hint>
                <mat-error>This is a required field</mat-error>
            </mat-form-field>

        </div>
        <div class="action-buttons">
            <button class="cvs-btn-secondary" matDialogClose>Cancel</button>
            <button class="cvs-btn-primary" (click)="saveFeedBack()">Submit</button>
        </div>
    </form>
</cvs-loader-overlay-container>