import {Component, OnInit, ViewChild} from '@angular/core';
import {TableauService} from "../services/tableau.service";
import {AuthorizationService, CVSAlertService, CVSAlertType} from "angular-component-library";
import {DomSanitizer} from "@angular/platform-browser";
import {AllView} from "../models/all-view";
import {CmxView} from "../models/cmx-view";
import {mergeMap} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {EnvConfig} from "../../config/env-config";
import {EnvironmentLoaderService} from "../../config/environment-loader.service";
import {UserService} from "../services/user.service";
import Utils from "../shared/utility/Utils";
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {FeedbackDialogComponent} from "../feedback-dialog/feedback-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatTabChangeEvent} from '@angular/material/tabs';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {FavoriteView} from "../models/favoriteView";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})

export class HomePageComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator | undefined;
  allViews: AllView[] = [];
  savedAllViews: AllView[] = [];
  savedRecentViews: AllView[] = [];
  pagedList: AllView[] = [];
  showSpinner: boolean = false;
  showAllViewed : boolean = true;
  showRecentlyViewed: boolean = false;
  product = "";
  protected readonly envConfig: EnvConfig;
  companyName = "CVS Health";
  selectedValue = "";
  toggleOptions: Array<String> = ["Thumbnail", "List"];
  showNoDashboardOverlay = false;
  labelName:string = "";
  title = "";
  selectedTabs: Array<String> = ['All', 'Favorites', 'Recently Updated'];
  selectedTabIndex = 0;
  length = 0;
  pageSize = 5;
  recentlyViewed = false;
  favoriteViewed = false;
  searchValue :any;
  favoriteDeleteIndex = 0;
  savedFavoriteViews: AllView[] = [];
  showFavoriteViewed = false;
  favoriteView : FavoriteView | undefined ;
  favoriteTabClick = false;
  cmxSelectedTabs: Array<String> = ['All'];
  cmxSelectedTabIndex = 0;

  constructor(private tableauService: TableauService, private authorizationService: AuthorizationService,
              private sanitizer: DomSanitizer, private matDialog: MatDialog,
              public translateService: TranslateService, private cvsAlertService: CVSAlertService,
              private readonly envService: EnvironmentLoaderService, private userService: UserService
              ) {
            this.envConfig = envService.getEnvConfig()
  }

  ngOnInit() {
    this.getImage();
    this.getExtUserInfo();
    this.product = this.translateService.currentLang;
    this.showAllViewed = this.envConfig.viewToolbar;
    this.selectedValue = "Thumbnail";
    this.selectedTabIndex = 0;
    console.log('Home page');
  }

  OnPageChange(event: PageEvent){
    let startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if(endIndex > this.length){
      endIndex = this.length;
    }
    this.pagedList = this.allViews.slice(startIndex, endIndex);
  }

  selectionChanged(item: MatButtonToggleChange) {
    this.selectedValue = item.value;
    this.pagedList = this.allViews.slice(0 , this.pageSize);
  }

  sortDashboards(dashboards: AllView[]) {
    return dashboards.sort((a, b) => (+b.favorite) - (+a.favorite)
        || a.name.localeCompare(b.name));
  }

  selectedTabValue(event:MatTabChangeEvent){
    this.searchValue = '';
    this.paginator?.firstPage();
    this.showNoDashboardOverlay = false;
    this.showAllViewed = false;
    this.showRecentlyViewed = false;
    this.showFavoriteViewed = false;
    this.labelName = event.tab.textLabel;
    this.allViews = [];
    this.pagedList = [];
    this.length = 0;
    //this.selectedValue = 'Thumbnail';
    if (this.selectedTabIndex == 0){
      this.showAllViewed = true;
      if (this.savedAllViews.length <=0) {
        this.getImage();
      }
      else {
        this.allViews = this.sortDashboards(this.savedAllViews);
        this.pagedList = this.allViews.slice(0 , this.pageSize);
        this.length = this.allViews.length;
      }
    }
    else if (this.selectedTabIndex == 1){
      this.showFavoriteViewed = true;
      this.favoriteTabClick = true;
      if ( this.savedFavoriteViews.length <=0 && !this.favoriteViewed ) {
        this.getFavoriteImage();
      } else {
        if (this.savedFavoriteViews.length <=0 ) {
          this.showNoDashboardOverlay = true;
        }else {
          this.allViews = this.sortDashboards(this.savedFavoriteViews);
          this.pagedList = this.allViews.slice(0 , this.pageSize);
          this.length = this.allViews.length;
        }
      }
    }
    else if (this.selectedTabIndex == 2){
      this.showRecentlyViewed = true;
      if ( this.savedRecentViews.length <=0 && !this.recentlyViewed ) {
        this.getRecentImage();
      } else {
        if (this.savedRecentViews.length <=0 ) {
          this.showNoDashboardOverlay = true;
        }else {
          this.allViews = this.sortDashboards(this.savedRecentViews);
          this.pagedList = this.allViews.slice(0 , this.pageSize);
          this.length = this.allViews.length;
        }
      }
    }
  }

  getImage() {
    this.showSpinner = true;
    if (this.showAllViewed) {
      this.authorizationService.getAuthorizedUser().pipe(mergeMap(user =>
          this.tableauService.getAllViewImg(user.email))).subscribe(dashboards => {
            this.showSpinner = false;
            if (dashboards.length > 0) {
              dashboards = this.sortDashboards(dashboards);
                this.allViews = dashboards.map(dashboard => {
                  let img;
                  if(dashboard.image != null) {
                    const objectURL = 'data:image/png;base64,' + dashboard.image;
                    img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                  } else{
                    img = "/assets/images/no_image_preview.png";
                  }
                const url = '/#/dashboard/' + dashboard.id + '/' + this.encodeRFC3986URIComponent(dashboard.name);
                const lastUpdated = Utils.toDateAgo(dashboard.updatedAt);
                return {image: img, url, name: dashboard.name, id: dashboard.id, updatedAt: lastUpdated,
                  favorite: dashboard.favorite, label: dashboard.name, folderName: dashboard.folderName }
              });
              this.pagedList = this.allViews.slice(0 , this.pageSize);
              this.length = this.allViews.length;
              this.savedAllViews = this.allViews;
            } else {
              this.showNoDashboardOverlay = true;
            }
          });
    }
  }

  getRecentImage() {
    this.allViews = [];
    this.recentlyViewed = true;
    this.showSpinner = true;
    if (this.showRecentlyViewed) {
      this.authorizationService.getAuthorizedUser().pipe(mergeMap(user =>
          this.tableauService.getRecentViewImg(user.email))).subscribe(dashboards => {
            this.showSpinner = false;
            if (dashboards.length > 0) {
              dashboards = this.sortDashboards(dashboards);
              this.showNoDashboardOverlay = false;
              this.allViews = dashboards.map(dashboard => {
                let img;
                if(dashboard.image != null) {
                  const objectURL = 'data:image/png;base64,' + dashboard.image;
                  img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                } else{
                  img = "/assets/images/no_image_preview.png";
                }
                const url = '/#/dashboard/' + dashboard.id + '/' + this.encodeRFC3986URIComponent(dashboard.name);
                const lastUpdated = Utils.toDateAgo(dashboard.updatedAt);
                return {image: img, url, name: dashboard.name, id: dashboard.id, updatedAt: lastUpdated,
                  favorite: dashboard.favorite, label: dashboard.name, folderName: dashboard.folderName }
              });
              if ( this.allViews.length > 0 ) {
                for ( var i = 0; i < this.savedAllViews.length; i++ ) {
                  for ( var e = 0; e < this.allViews.length; e++ ) {
                    if ( this.savedAllViews[i].id == this.allViews[e].id ) {
                      this.savedRecentViews.push( this.allViews[e] )
                    }
                  }
                }
                if (this.savedRecentViews.length > 0) {
                  this.allViews = this.savedRecentViews;
                  this.length = this.allViews.length;
                  this.pagedList = this.allViews.slice(0 , this.pageSize);
                } else {
                  this.showNoDashboardOverlay = true;
                }
              }
            } else {
              this.showNoDashboardOverlay = true;
            }
          });
    }
  }

  getFavoriteImage() {
    this.allViews = [];
    this.favoriteViewed = true;
    this.showSpinner = true;
    if (this.showFavoriteViewed) {
      this.authorizationService.getAuthorizedUser().pipe(mergeMap(user =>
          this.tableauService.getFavoriteImg(user.email))).subscribe(dashboards => {
            this.showSpinner = false;
            if (dashboards.length > 0) {
              dashboards = dashboards.sort((a, b) => a.name.localeCompare(b.name));
                this.showNoDashboardOverlay = false;
                this.allViews = dashboards.map(dashboard => {
                  let img;
                  if(dashboard.image != null) {
                    const objectURL = 'data:image/png;base64,' + dashboard.image;
                    img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                  } else{
                    img = "/assets/images/no_image_preview.png";
                  }
                const url = '/#/dashboard/' + dashboard.id + '/' + this.encodeRFC3986URIComponent(dashboard.name);
                const lastUpdated = Utils.toDateAgo(dashboard.updatedAt);
                return {image: img, url, name: dashboard.name, id: dashboard.id, updatedAt: lastUpdated,
                  favorite: dashboard.favorite, label: dashboard.label, folderName: dashboard.folderName}
              });
              this.savedFavoriteViews = [];
              if ( this.allViews.length > 0 ) {
                for ( var i = 0; i < this.savedAllViews.length; i++ ) {
                  for ( var e = 0; e < this.allViews.length; e++ ) {
                    if ( this.savedAllViews[i].id == this.allViews[e].id ) {
                      this.savedFavoriteViews.push( this.allViews[e] )
                    }
                  }
                }
                if (this.savedFavoriteViews.length > 0) {
                  this.allViews = this.savedFavoriteViews;
                  this.length = this.allViews.length;
                  this.pagedList = this.allViews.slice(0 , this.pageSize);
                } else {
                  this.showNoDashboardOverlay = true;
                }
              }
            } else {
              this.showNoDashboardOverlay = true;
            }
          });
    }
  }

  searchFilter(searchValue: any) {
    if (this.selectedTabIndex === 0){
      this.filter(searchValue, this.savedAllViews)
    } else if(this.selectedTabIndex === 1){
      this.filter(searchValue, this.savedFavoriteViews)
    } else if(this.selectedTabIndex === 2){
      this.filter(searchValue, this.savedRecentViews)
    }
  }

  private filter(searchValue: string , currentView: AllView[]) {
    this.paginator?.firstPage();
    if(searchValue === ''){
      this.allViews = currentView;
      this.length = currentView.length;
      this.pagedList = currentView.slice(0 , this.pageSize);
      this.showNoDashboardOverlay = false;
    } else {
      this.allViews = currentView.filter((board) => {
        return board.name.toLowerCase().includes(searchValue.toLowerCase());
      });
      if (this.allViews.length < 1) {
        this.showNoDashboardOverlay = true;
      } else {
        this.showNoDashboardOverlay = false;
        this.pagedList = this.allViews.slice(0 , this.pageSize);
      }
      this.length = this.allViews.length;
    }
  }

  processAddImage(aView: AllView){
    aView.favorite = true;
    this.addFavoriteImage(aView);
  }

  addFavoriteImage(aView: AllView ) {
    this.authorizationService.getAuthorizedUser().subscribe(user => {
      this.tableauService.addFavoriteImg({
        ...(this.favoriteView),
        name: aView.name,
        id: aView.id,
        label: aView.label,
        email: user.email,
        favorite: aView.favorite
      }).subscribe(() => {
        if (this.favoriteTabClick) {
          this.savedFavoriteViews.push(aView);
        }
        this.resetFavoriteButtonOnAdd(aView);
      }, () => {
      });
    })
  }

  resetFavoriteButtonOnAdd(aView:AllView){
    if (this.savedAllViews.length > 0) {
      const allIndex = this.savedAllViews.findIndex(x => x.id === aView.id);
      if (allIndex >= 0) {
        this.savedAllViews[allIndex].favorite = true;
      }
    }
    if (this.savedRecentViews.length > 0) {
      const recentIndex = this.savedRecentViews.findIndex(x => x.id === aView.id);
      if (recentIndex >= 0){
        this.savedRecentViews[recentIndex].favorite = true;
      }
    }
  }

  deleteFavoriteImage(aView:AllView ) {
    aView.favorite = false;
    if (this.showFavoriteViewed) {
      this.allViews = [];
      this.pagedList = [];
      this.showSpinner = true;
    }
    this.authorizationService.getAuthorizedUser().subscribe(user => {
      this.tableauService.deleteFavoriteImg(user.email, aView.id)
          .subscribe(() => {
            this.resetFavoriteButtonOnDelete(aView.id);
            if (this.showFavoriteViewed)
              this.showSpinner = false;
          }, () => {
            //this.showSpinner = false;
          });
    })
  }

  resetFavoriteButtonOnDelete(id:string){
    if (this.savedAllViews.length > 0) {
      const allIndex = this.savedAllViews.findIndex(x => x.id === id);
      if (allIndex >= 0) {
        this.savedAllViews[allIndex].favorite = false;
      }
    }
    if (this.savedRecentViews.length > 0) {
      const recentIndex = this.savedRecentViews.findIndex(x => x.id === id);
      if (recentIndex >= 0){
        this.savedRecentViews[recentIndex].favorite = false;
      }
    }
    this.favoriteDeleteIndex = this.savedFavoriteViews.findIndex(x => x.id === id);
    this.savedFavoriteViews.splice(this.favoriteDeleteIndex, 1);
    if (this.showFavoriteViewed && this.showFavoriteViewed) {
      if(this.savedFavoriteViews.length <=0){
        this.showNoDashboardOverlay = true;
      }
      else {
        this.allViews = this.savedFavoriteViews;
        this.pagedList = this.allViews.slice(0, this.pageSize);
        this.length = this.allViews.length;
      }
    }
  }

  getExtUserInfo() {
    this.authorizationService.getAuthorizedUser().subscribe(user => {
      const email = user.email;
      const product = this.translateService.currentLang;
      this.userService.getExternalUserInfo(product, email)
          .subscribe(userInfo => {
            this.companyName = userInfo.userAttributes?.attr1 ? userInfo.userAttributes?.attr1 : this.companyName;
            this.title = "Welcome, " + user.firstName + " from " + this.companyName;
          })
    });
  }

  addFeedback() {
    this.matDialog.open(FeedbackDialogComponent, {
      width: '30%',
      height: 'auto'
    }).afterClosed().subscribe(response => {
      if (response) {
        this.cvsAlertService.sendAlert({
          headline: 'Success',
          body: 'Your feedback has been submitted.',
          alertType: CVSAlertType.Success,
          removedAfterMilliseconds: 10000,
          fadeClass: true
        });
      }
    })
  }

  encodeRFC3986URIComponent(url : string) {
    return encodeURIComponent(url)
      .replace(
        /[!'()*]/g,
        (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
      );
  }

  cmxSelectedTabValue(event:MatTabChangeEvent){
    this.searchValue = '';
    this.paginator?.firstPage();
    this.showNoDashboardOverlay = false;
    this.showAllViewed = false;
    this.labelName = event.tab.textLabel;
    this.length = 0;
    //this.selectedValue = 'Thumbnail';
  }

   cmxViewList: CmxView[] = [
    {
      id: "Winter Wellness",
      name: "Start the year off well for your brand",
      image: "/assets/images/Wellness.png",
      details: "Check out the Winter Wellness Hub and connect with shoppers during key moments from the new year to cold and flu season.",
      url: "https://www.cvsmediaexchange.com/insights/winter-wellness-hub",
      cmxType: "Webpage"
    },
     {
       id: "Insights",
       name: "Dive into our latest insights",
       image: "/assets/images/Latest_Insights.png",
       details: "From case studies to the latest articles from our newsletter, The CMX Pulse.",
       url: "https://www.cvsmediaexchange.com/insights",
       cmxType: "Webpage"
     },
     {
       id: "Ad-solutions",
       name: "Explore omnichannel solutions",
       image: "/assets/images/Omnichannel_Solutions.png",
       details: "Reach ExtraCare shoppers as they browse cvs.com, visit our 9,000+ locations, or engage with offsite content.",
       url: "https://www.cvsmediaexchange.com/solutions",
       cmxType: "Webpage"
     },
     {
       id: "Ad-Specs",
       name: "Download our latest advertising guides",
       image: "/assets/images/Ad_Guides.png",
       details: "We’ve blueprinted CMX advertising specs to help you easily build the assets for your next campaign.",
       url: "https://www.cvsmediaexchange.com/ad-specs",
       cmxType: "Webpage"
     },
     {
       id: "Cmx-News",
       name: "CMX in the news",
       image: "/assets/images/CMX_in_the_News.png",
       details: "Stay up to date on the latest industry media coverage detailing how CMX is changing the retail media landscape.",
       url: "https://www.cvsmediaexchange.com/about-us/in-the-news",
       cmxType: "Webpage"
     }
  ];
}