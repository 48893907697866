import {Observable} from "rxjs";
import {UserDetails} from "../models/user-details";
import {map} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {EnvConfig} from "../../config/env-config";
import {CVSHttpClient} from "angular-component-library";
import {EnvironmentLoaderService} from "../../config/environment-loader.service";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    protected readonly envConfig: EnvConfig;
    protected readonly backendBaseUrl: string;

    constructor(private cvsHttpClient: CVSHttpClient, private readonly envService: EnvironmentLoaderService) {
        this.envConfig = envService.getEnvConfig()
        this.backendBaseUrl = this.envConfig.backendBaseUrl;
    }

    getExternalUserInfo(product: string, email: string): Observable<UserDetails> {
        return this.cvsHttpClient.get(`${this.backendBaseUrl}/${product}/${email}/getExternalUserInfo`);
    }
}