import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "../services/message.service";
import {MatDialogRef} from "@angular/material/dialog";
import {AuthorizationService, CVSAlertService, CVSAlertType} from "angular-component-library";

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {
  feedbackForm!: FormGroup;
  errorTextField = false;
  isLoading = false;
  maxLength = 1000;
  feedbackTypes: any = ['Select','Access Issue','Dashboard Question','General Feedback']
  selectedValue: any;

  constructor(private formBuilder: FormBuilder, private messageService: MessageService,
              private cvsAlertService: CVSAlertService, private dialogRef: MatDialogRef<any>,
              private authorizationService: AuthorizationService) {
    this.selectedValue = this.feedbackTypes[0]

  }

  ngOnInit(): void {
    this.feedbackForm = this.formBuilder.group({
      feedbackText: ['', Validators.required]
    });
  }

  saveFeedBack() {
    if (this.feedbackForm.valid && this.validateFeedbackType()) {
      this.isLoading = true;
      this.authorizationService.getAuthorizedUser().subscribe(user => {
        this.messageService.saveMessage({
          ...user,
          message: this.feedbackForm.controls.feedbackText.value,
          feedbackType: this.selectedValue
        }).subscribe(() => {
          this.dialogRef.close(this.feedbackForm.controls.feedbackText.value)
        }, () => {
          this.isLoading = false;
          this.displayError('Unable to submit your feedback, please try again.');
        });
      })
    } else {
      this.errorTextField = true;
      this.displayError('All fields are required.')
    }
  }

  displayError(headlineMessage: string) {
    this.cvsAlertService.sendAlert({
      alertDocumentHolderName: 'dialog-alert-placeholder',
      headline: headlineMessage,
      alertType: CVSAlertType.Error,
      removedAfterMilliseconds: 3000
    });
  }

  private validateFeedbackType() {
    console.log('validateFeedbackType: '+ this.selectedValue)
    return this.selectedValue!=this.feedbackTypes[0];
  }
}