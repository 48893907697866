<cvs-base [baseConfig]="baseConfig" [authConfig]="authConfig" [sideNavConfig]="sideNavConfig">
    <mat-icon *ngIf="!notificationsFlag" svgIcon="bell--s" matBadge="{{notificationsCount}}" matBadgeSize="small"
              (click)="notificationDialog()" matBadgeColor="accent" matBadgePosition="above after" class="bellIcon">
    </mat-icon>
    <mat-icon *ngIf="notificationsFlag" svgIcon="bell--s" (click)="notificationDialog()" class="bellIcon"></mat-icon>
    <table class="mainTable">
        <tr>
            <cvs-alert-placeholder></cvs-alert-placeholder></tr>
    </table>
    <router-outlet></router-outlet>
</cvs-base>