import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
    AuthCodePKCEService,
    AuthenticationService,
    CVSAlertModule,
    CVSBaseModule,
    CVSLoaderModule
} from "angular-component-library";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HomePageComponent} from './home-page/home-page.component';
import {DashboardPageComponent} from './dashboard-page/dashboard-page.component';
import {MatIconModule} from "@angular/material/icon";
import {FeedbackDialogComponent} from './feedback-dialog/feedback-dialog.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from "@angular/material/dialog";
import {MatBadgeModule} from "@angular/material/badge";
import {DialogAlertPlaceholderComponent} from "./shared/cvs-custom-placeholder/cvs-custom-placeholder.component";
import {
    MaintenanceAlertPlaceholderComponent
} from "./shared/cvs-custom-placeholder/maintenance-alert-placeholder.component";
import {
    NotificationAlertPlaceholderComponent
} from "./shared/cvs-custom-placeholder/notification-alert-placeholder.component";
import {OtherAlertPlaceholderComponent} from "./shared/cvs-custom-placeholder/other-alert-placeholder.component";
import { UsefulLinksComponent } from './useful-links/useful-links.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {EnvironmentLoaderService} from "../config/environment-loader.service";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatListModule} from "@angular/material/list";
import {MatTabsModule} from "@angular/material/tabs";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSelectModule} from "@angular/material/select";
import {NotificationsDialogComponent} from "./notification-dialog/notifications-dialog.component";

const initAppFn = (envService: EnvironmentLoaderService) => {
    if(location.hostname.includes("dev")){
        return () => envService.loadEnvConfig('/assets/config/app-config-dev.json');
    }else if(location.hostname.includes("uat")){
        return () => envService.loadEnvConfig('/assets/config/app-config-uat.json');
    }else if(location.hostname.includes("stg")){
        return () => envService.loadEnvConfig('/assets/config/app-config-stg.json');
    }else if(location.hostname === "cmxanalytics.cvshealth.com"){
        return () => envService.loadEnvConfig('/assets/config/app-config-prod.json');
    }
    return () => envService.loadEnvConfig('/assets/config/app-config.json');
};

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        DashboardPageComponent,
        FeedbackDialogComponent,
        DialogAlertPlaceholderComponent,
        MaintenanceAlertPlaceholderComponent,
        NotificationAlertPlaceholderComponent,
        OtherAlertPlaceholderComponent,
        UsefulLinksComponent,
        NotificationsDialogComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatTabsModule,
        MatBadgeModule,
        MatPaginatorModule,
        CVSBaseModule,
        CVSLoaderModule,
        CVSAlertModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatButtonToggleModule,
        MatListModule,
        MatTabsModule,
        MatPaginatorModule,
        MatSelectModule,
        FormsModule,
    ],
    providers: [
        EnvironmentLoaderService,
        {
            provide: APP_INITIALIZER,
            useFactory: initAppFn,
            multi: true,
            deps: [EnvironmentLoaderService],
        },
        {provide: AuthenticationService, useClass: AuthCodePKCEService},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}