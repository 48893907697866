import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {EnvConfig} from "./env-config";

@Injectable()
export class EnvironmentLoaderService {
  private envConfig!: EnvConfig;

  constructor(private readonly http: HttpClient) {}

  async loadEnvConfig(configPath: string): Promise<void> {
    this.envConfig = await this.http.get<EnvConfig>(configPath).toPromise();
  }

  getEnvConfig(): EnvConfig {
    return this.envConfig;
  }
}
